<script setup lang="ts">
import type { VerticalNavigationLink } from '#ui/types';

const { t } = useI18n();

// TODO: Make a auto generated navigation based on all subroutes in /account and display the correct localized title
const links = computed<VerticalNavigationLink[]>(() => ([
	{
		label: t('page.account.title'),
		to: '/account',
		icon: 'i-heroicons-user-circle',
	},
	{
		label: t('page.account.socialMedia.title'),
		to: '/account/social-media',
		icon: 'i-heroicons-identification',
	},
	{
		label: t('page.account.password.title'),
		to: '/account/password',
		icon: 'i-heroicons-lock-closed',
	},
	{
		label: t('page.account.payment.title'),
		to: '/account/payment',
		icon: 'i-heroicons-wallet',
	},
	{
		label: t('page.account.payout.title'),
		to: '/account/payout',
		icon: 'i-heroicons-credit-card',
	},
]));

const mobileNavigationScrollContainer = ref<HTMLElement>();

const { onClick: onMobileNavigationClick } = useHorizontalNavigationScroller(mobileNavigationScrollContainer);
</script>

<template>
	<NuxtLayout name="default">
		<div class="grid xl:grid-cols-12 gap-5">
			<div class="hidden xl:block col-span-2">
				<UVerticalNavigation
					:links="links"
					:ui="{
						active: 'text-gray-900 before:bg-slate-300',
					}"
				/>
			</div>
			<div
				ref="mobileNavigationScrollContainer"
				class="xl:hidden w-full overflow-auto"
			>
				<UHorizontalNavigation
					ref="mobileNavigationScrollContainer"
					:links="links"
					:ui="{ inner: '', active: 'js-active' }"
					@click="onMobileNavigationClick"
				/>
			</div>
			<div class="xl:col-span-10">
				<UCard :ui="{ body: { padding: 'px-4 py-5 sm:p-12' } }">
					<p class="text-3xl font-bold mb-6 md:mb-10 text-center xl:text-left">
						{{ $route.meta.title ? t($route.meta.title as string) : t('page.account.title') }}
					</p>
					<slot />
				</UCard>
			</div>
		</div>
	</NuxtLayout>
</template>
