/**
 * This composable enhances the user experience (UX) for the Nuxt UI component `UHorizontalNavigation`
 * by automatically scrolling to the currently selected navigation item.
 *
 * How it works:
 * - The active navigation item must be assigned the class `.js-active`.
 * - Once applied, the composable detects this class and scrolls the item into view, ensuring
 *   seamless navigation in horizontally scrollable menus.
 *
 * Usage:
 * - Ensure the class `.js-active` is set on the active navigation item.
 * - The composable will manage the scroll behavior to provide a smoother UX.
 */

export const useHorizontalNavigationScroller = (element: MaybeRef<HTMLElement | undefined>) => {
	const { x: mobileNavigationScrollX } = useScroll(element, { behavior: 'smooth' });

	const scrollToNavigationItem = (el: Element) => {
		const rect = el.getBoundingClientRect();
		const centerX = (((rect?.left ?? 0) + mobileNavigationScrollX.value) + ((rect?.width ?? 0) / 2)) - ((unref(element)?.clientWidth ?? 0) / 2);
		mobileNavigationScrollX.value = centerX;
	};

	const onClick = (event: PointerEvent) => {
		const targetElement = event.target as HTMLElement;
		const offsetElement = targetElement.tagName === 'A' ? targetElement : targetElement.offsetParent;

		if (!offsetElement || offsetElement.tagName !== 'A') return;

		scrollToNavigationItem(offsetElement);
	};

	onMounted(() => {
		const activeMobileNavigationItem = unref(element)?.querySelector('.js-active');
		if (activeMobileNavigationItem) scrollToNavigationItem(activeMobileNavigationItem);
	});

	return {
		onClick,
	};
};
